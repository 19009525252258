import React from 'react'
import './contentloader.scss'
const ContentLoader = (props) => {
    return (
        <>
            {/* Placeholder Content */}
<div className="placeholder-content">
 
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />
  <div className="placeholder-content_item" />


</div>

        </>
    )
}

export default ContentLoader
